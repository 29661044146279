<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                
            </div>

            <div class="col-6 col-lg-2">
                
                <div class="card text-white primary elevation-5">
                    <div class="card-body">
                        <p class="card-title">Order {{ subMonthYear ? subMonthYear : 'Loading...' }}</p>
                        <p class="">{{ subMonthYearTotalIDR ? subMonthYearTotalIDR : 'Loading...' }} IDR</p>
                        <p class="">{{ subMonthYearTotalOrder ? subMonthYearTotalOrder : 'Loading...' }} Orders</p>
                        <div v-if="orderPercentSub < 0">
                            <p class="">{{ subMonthYearTotalWgt ? subMonthYearTotalWgt : 'Loading...' }} Tons <span class="badge text-warning">{{ orderPercentSub ? orderPercentSub : 'Loading...' }} %</span> </p>
                        </div>
                        <div v-else>
                            <p class="">{{ subMonthYearTotalWgt ? subMonthYearTotalWgt : 'Loading...' }} Tons <span class="badge text-warning">{{ orderPercentSub ? orderPercentSub : 'Loading...' }} %</span> </p>
                        </div>
                    </div>
                        <div id="divider" style="background-color:#ffffff; height: 1px; width:100%;"></div>
                    <div class="card-body">
                        <p class="card-title">Order {{ subMonthSubYear ? subMonthSubYear : 'Loading...' }}</p>
                        <p class="">{{ subMonthSubYearTotalIDR ? subMonthSubYearTotalIDR : 'Loading...' }} IDR</p>
                        <p class="">{{ subMonthSubYearTotalOrder ? subMonthSubYearTotalOrder : 'Loading...' }} Orders</p>
                        <p class="">{{ subMonthSubYearTotalWgt ? subMonthSubYearTotalWgt : 'Loading...' }} Tons</p>
                    </div>
                </div>

            </div>

            <div class="col-6 col-lg-2">

                <div class="card text-white primary elevation-5">
                    <div class="card-body">

                        <p class="card-title">Order {{ monthYear ? monthYear : 'Loading...' }}</p>
                        <p class="">{{ monthYearTotalIDR ? monthYearTotalIDR : 'Loading...' }} IDR</p>
                        <p class="">{{ monthYearTotalOrder ? monthYearTotalOrder : 'Loading...' }} Orders</p>
                        <div v-if="orderPercentSub < 0">
                            <p class="">{{ monthYearTotalWgt ? monthYearTotalWgt : 'Loading...' }} Tons <span class="badge text-warning">{{ orderPercent ? orderPercent : 'Loading...' }} %</span> </p>
                        </div>
                        <div v-else>
                            <p class="">{{ monthYearTotalWgt ? monthYearTotalWgt : 'Loading...' }} Tons <span class="badge text-warning">{{ orderPercent ? orderPercent : 'Loading...' }} %</span> </p>
                        </div>
                    </div>
                        <div id="divider" style="background-color:#ffffff; height: 1px; width:100%;"></div>
                    <div class="card-body">
                        <p class="card-title">Order {{ monthSubYear ? monthSubYear : 'Loading...' }}</p>
                        <p class="">{{ monthSubYearTotalIDR ? monthSubYearTotalIDR : 'Loading...' }} IDR</p>
                        <p class="">{{ monthSubYearTotalOrder ? monthSubYearTotalOrder : 'Loading...' }} Orders</p>
                        <p class="">{{ monthSubYearTotalWgt ? monthSubYearTotalWgt : 'Loading...' }} Tons</p>
                    </div>

                </div>

            </div>

            <div class="col-6 col-lg-2">

                <div class="card text-white primary elevation-5">
                    <div class="card-body">
                        <p class="card-title">Invoice {{ subMonthYear ? subMonthYear : 'Loading...' }}</p>
                        <p class="">{{ invSubMonthYearTotalIDR ? invSubMonthYearTotalIDR : 'Loading...' }} IDR</p>
                        <p class="">{{ invSubMonthYearTotalOrder ? invSubMonthYearTotalOrder : 'Loading...' }} Orders</p>
                        <p class="">{{ invSubMonthYearTotalWgt ? invSubMonthYearTotalWgt : 'Loading...' }} Tons</p>
                    </div>
                        <div id="divider" style="background-color:#ffffff; height: 1px; width:100%;"></div>
                    <div class="card-body">
                        <p class="card-title">Invoice {{ subMonthSubYear ? subMonthSubYear : 'Loading...' }}</p>
                        <p class="">{{ invSubMonthSubYearTotalIDR ? invSubMonthSubYearTotalIDR : 'Loading...' }} IDR</p>
                        <p class="">{{ invSubMonthSubYearTotalOrder ? invSubMonthSubYearTotalOrder : 'Loading...' }} Orders</p>
                        <p class="">{{ invSubMonthSubYearTotalWgt ? invSubMonthSubYearTotalWgt : 'Loading...' }} Tons</p>
                    </div>
                </div>

            </div>

            <div class="col-6 col-lg-2">

                <div class="card text-white primary elevation-5">
                    <div class="card-body">
                        <p class="card-title">Invoice {{ monthYear ? monthYear : 'Loading...' }}</p>
                        <p class="">{{ invMonthYearTotalIDR ? invMonthYearTotalIDR : 'Loading...' }} IDR</p>
                        <p class="">{{ invMonthYearTotalOrder ? invMonthYearTotalOrder : 'Loading...' }} Orders</p>
                        <p class="">{{ invMonthYearTotalWgt ? invMonthYearTotalWgt : 'Loading...' }} Tons</p>
                    </div>
                        <div id="divider" style="background-color:#ffffff; height: 1px; width:100%;"></div>
                    <div class="card-body">
                        <p class="card-title">Invoice {{ monthSubYear ? monthSubYear : 'Loading...' }}</p>
                        <p class="">{{ invMonthSubYearTotalIDR ? invMonthSubYearTotalIDR : 'Loading...' }} IDR</p>
                        <p class="">{{ invMonthSubYearTotalOrder ? invMonthSubYearTotalOrder : 'Loading...' }} Orders</p>
                        <p class="">{{ invMonthSubYearTotalWgt ? invMonthSubYearTotalWgt : 'Loading...' }} Tons</p>
                    </div>
                </div>

            </div>

            <div class="col-6 col-lg-2">

                <!-- <div class="card text-white primary elevation-5">
                    <div class="card-body">
                        <p class="card-title">Outlet Info {{ subMonthYear ? subMonthYear : 'Loading...' }}</p>
                        <div class="flex-container">
                            <p>New Customer</p>
                            <p><v-chip class="ma-1" small color="green" dark>{{ newCustomerSubMonth ? newCustomerSubMonth : 'Loading...' }}</v-chip></p>
                        </div>
                        <div class="flex-container">
                            <p>Active Outlet</p>
                            <p><v-chip class="ma-1" small color="green" dark>{{ activeOutletSubMonth ? activeOutletSubMonth : 'Loading...' }}</v-chip></p>
                        </div>
                        <div class="flex-container">
                            <p>Total Customer Visit</p>
                            <p><v-chip class="ma-1" small color="green" dark>{{ customerVisitSubMonth ? customerVisitSubMonth : 'Loading...' }}</v-chip></p>
                        </div>
                    </div>
                        <div id="divider" style="background-color:#ffffff; height: 1px; width:100%;"></div>
                    <div class="card-body">
                        <p class="card-title">Outlet Info {{ monthYear ? monthYear : 'Loading...' }}</p>
                        <div class="flex-container">
                            <p>New Customer</p>
                            <p><v-chip class="ma-1" small color="green" dark>{{ newCustomerThisMonth ? newCustomerThisMonth : 'Loading...' }}</v-chip></p>
                        </div>
                        <div class="flex-container">
                            <p>Active Outlet</p>
                            <p><v-chip class="ma-1" small color="green" dark>{{ activeOutletThisMonth ? activeOutletThisMonth : 'Loading...' }}</v-chip></p>
                        </div>
                        <div class="flex-container">
                            <p>Total Customer Visit</p>
                            <p><v-chip class="ma-1" small color="green" dark>{{ customerVisitThisMonth ? customerVisitThisMonth : 'Loading...' }}</v-chip></p>
                        </div>
                    </div>
                </div> -->

                <div class="card text-white primary elevation-5">
                    <div class="card-body">
                        <!-- <p class="card-title">Outlet Info {{ subMonthYear ? subMonthYear : 'Loading...' }}</p> -->
                        <p class="card-title">Outlet Info {{ subMonthYear ? subMonthYear : 'Loading...'}}</p>
                        <div class="flex-container">
                            <p>New Customer</p>
                            <p><v-chip class="ma-1" small color="green" dark @click="newCustomerDetails(subMonthNum, year)">{{ newCustomerSubMonth ? newCustomerSubMonth : 'Loading...' }}</v-chip></p>
                        </div>
                        <div class="flex-container">
                            <p>Active Outlet</p>
                            <p><v-chip class="ma-1" small color="green" dark @click="newOutletDetails(subMonthNum, year)">{{ activeOutletSubMonth ? activeOutletSubMonth : 'Loading...' }}</v-chip></p>
                        </div>
                        <div class="flex-container">
                            <p>Total Customer Visit</p>
                            <p><v-chip class="ma-1" small color="green" dark @click="newVisitDetails(subMonthNum, year)">{{ customerVisitSubMonth ? customerVisitSubMonth : 'Loading...' }}</v-chip></p>
                        </div>
                    </div>
                </div>
                
            </div>

            <div class="col-6 col-lg-2">

                <!-- <div class="card text-white primary elevation-5">
                    <div class="card-body">

                        <p class="card-title">Outlet Info {{ subMonthYear ? subMonthYear : 'Loading...' }}</p>
                        <div class="flex-container">
                            <p>New Customer</p>
                            <p><v-chip class="ma-1" small color="green" dark>{{ newCustomerSubMonth ? newCustomerSubMonth : 'Loading...' }}</v-chip></p>
                        </div>
                        <div class="flex-container">
                            <p>Active Outlet</p>
                            <p><v-chip class="ma-1" small color="green" dark>{{ activeOutletSubMonth ? activeOutletSubMonth : 'Loading...' }}</v-chip></p>
                        </div>
                        <div class="flex-container">
                            <p>Total Customer Visit</p>
                            <p><v-chip class="ma-1" small color="green" dark>{{ customerVisitSubMonth ? customerVisitSubMonth : 'Loading...' }}</v-chip></p>
                        </div>

                    </div>
                </div> -->

                <div class="card text-white primary elevation-5">
                    <div class="card-body">
                        <p class="card-title">Outlet Info {{ monthYear ? monthYear : 'Loading...' }}</p>
                        <div class="flex-container">
                            <p>New Customer</p>
                            <p><v-chip class="ma-1" small color="green" dark @click="newCustomerDetails(monthNum, year)">{{ newCustomerThisMonth ? newCustomerThisMonth : 'Loading...' }}</v-chip></p>
                        </div>
                        <div class="flex-container">
                            <p>Active Outlet</p>
                            <p><v-chip class="ma-1" small color="green" dark @click="newOutletDetails(monthNum, year)">{{ activeOutletThisMonth ? activeOutletThisMonth : 'Loading...' }}</v-chip></p>
                        </div>
                        <div class="flex-container">
                            <p>Total Customer Visit</p>
                            <p><v-chip class="ma-1" small color="green" dark @click="newVisitDetails(monthNum, year)">{{ customerVisitThisMonth ? customerVisitThisMonth : 'Loading...' }}</v-chip></p>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-12 col-lg-6">
                <div class="card elevation-5">
                    <div class="card-body">
                        <p class="card-title">Total Order in {{ subYear }} - {{ year }}</p>
                        <div id="chartContainer1" style="height: 300px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-2">
                <div class="card elevation-5">
                    <div class="card-body">
                        <p class="card-title">Performance in {{ subYear ? subYear : 'Loading...' }}</p>
                        <div id="chartContainer2" style="height: 300px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-2">
                <div class="card elevation-5">
                    <div class="card-body">
                        <p class="card-title">Performance in {{ year ? year : 'Loading...' }}</p>
                        <div id="chartContainer3" style="height: 300px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-2">
                <div class="card elevation-5">
                    <div class="card-body">
                        <p class="card-title">Performance in {{ monthYear ? monthYear : 'Loading...' }}</p>
                        <div id="chartContainer4" style="height: 300px; width: 100%;"></div>
                    </div>
                </div>
            </div>

        </div>

        <v-dialog v-model="newCustomerDialog" max-width="1024px">
            <div class="card text-white elevation-5">
                <div class="card-body">
                    <v-data-table :headers="newCustomerDetailsHeader" :items="newCustomerDetailsLists" class="elevation-1" :loading="newCustomerDetailsLoading" dense></v-data-table>
                </div>
            </div>
        </v-dialog>

        <v-dialog v-model="newOutletDialog" max-width="1024px">
            <div class="card text-white elevation-5">
                <div class="card-body">
                    <v-data-table :headers="newOutletDetailsHeader" :items="newOutletDetailsLists" class="elevation-1" :loading="newOutletDetailsLoading" dense></v-data-table>
                </div>
            </div>
        </v-dialog>

        <v-dialog v-model="newVisitDialog" max-width="1024px">
            <div class="card text-white elevation-5">
                <div class="card-body">
                    <v-data-table :headers="newVisitDetailsHeader" :items="newVisitDetailsLists" class="elevation-1" :loading="newVisitDetailsLoading" dense></v-data-table>
                </div>
            </div>
        </v-dialog>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Dashboard',
                disabled: false,
                href: '/admin/kmb/dashboard',
                },
                {
                text: 'AnalyticDashboard',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            subYear: '',
            year:'',
            subMonthNum: '',
            monthNum: '',
            subMonthYear: '',
            subMonthYearTotalIDR: '',
            subMonthYearTotalOrder: '',
            subMonthYearTotalWgt: '',
            /*-------------------------------*/
            subMonthSubYear: '',
            subMonthSubYearTotalIDR: '',
            subMonthSubYearTotalOrder: '',
            subMonthSubYearTotalWgt: '',
            /*-------------------------------*/
            monthYear: '',
            monthYearTotalIDR: '',
            monthYearTotalOrder: '',
            monthYearTotalWgt: '',
            /*-------------------------------*/
            monthSubYear: '',
            monthSubYearTotalIDR: '',
            monthSubYearTotalOrder: '',
            monthSubYearTotalWgt: '',
            /*-------------------------------*/
            invSubMonthYear: '',
            invSubMonthYearTotalIDR: '',
            invSubMonthYearTotalOrder: '',
            invSubMonthYearTotalWgt: '',
            /*-------------------------------*/
            invSubMonthSubYear: '',
            invSubMonthSubYearTotalIDR: '',
            invSubMonthSubYearTotalOrder: '',
            invSubMonthSubYearTotalWgt: '',
            /*-------------------------------*/
            invMonthYear: '',
            invMonthYearTotalIDR: '',
            invMonthYearTotalOrder: '',
            invMonthYearTotalWgt: '',
            /*-------------------------------*/
            invMonthSubYear: '',
            invMonthSubYearTotalIDR: '',
            invMonthSubYearTotalOrder: '',
            invMonthSubYearTotalWgt: '',
            /*-------------------------------*/
            orderPercent: '',
            orderPercentSub: '',
            /*-------------------------------*/
            newCustomerSubMonth: '',
            activeOutletSubMonth: '',
            customerVisitSubMonth: '',
            /*-------------------------------*/
            newCustomerThisMonth: '',
            activeOutletThisMonth: '',
            customerVisitThisMonth: '',
            userGroup: null,
            appl_id: 'DASHBOARD',
            newCustomerDialog: false,
            newCustomerDetailsHeader: [
                { text: 'OFFICE', value: 'office', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SALESMAN', value: 'NamaSales', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER', value: 'count_customer', align: 'right', class: 'primary--text blue lighten-5' },
            ],
            newCustomerDetailsLists: [],
            newCustomerDetailsLoading: false,
            newOutletDialog: false,
            newOutletDetailsHeader: [
                { text: 'OFFICE', value: 'office', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SALESMAN', value: 'salesman_name', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER', value: 'total', align: 'right', class: 'primary--text blue lighten-5' },
            ],
            newOutletDetailsLists: [],
            newOutletDetailsLoading: false,
            newVisitDialog: false,
            newVisitDetailsHeader: [
                { text: 'OFFICE', value: 'office', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SALESMAN', value: 'NamaSales', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER', value: 'total', align: 'right', class: 'primary--text blue lighten-5' },
            ],
            newVisitDetailsLists: [],
            newVisitDetailsLoading: false
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{

        async initialize(){

            this.$store.dispatch('setOverlay', true)

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/dashboard/getDashboardOrderChart1`, { 
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                
                this.renderChart1(res.data.data2, res.data.data1)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/dashboard/getDashboardOrderChart2`, { 
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {

                this.renderChart2(res.data.data)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/dashboard/getDashboardOrderChart3`, { 
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                
                this.renderChart3(res.data.data)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/dashboard/getDashboardOrderChart4`, { 
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                
                this.renderChart4(res.data.data)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/dashboard/getDashboardItems`, { 
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {

                this.subYear = res.data.subYear
                this.year = res.data.year
                this.subMonthNum = res.data.subMonthNum
                this.monthNum = res.data.monthNum

                this.orderPercent = res.data.orderPercent
                this.orderPercentSub = res.data.orderPercentSub
                this.subMonthYear = res.data.lastMonth
                this.subMonthYearTotalIDR = res.data.orderSubMonthYear
                this.subMonthYearTotalOrder = res.data.countOrderSubMonthYear
                this.subMonthYearTotalWgt = res.data.wgtSubMonthYear
                this.subMonthSubYear = res.data.lastYearMonth
                this.subMonthSubYearTotalIDR = res.data.orderSubMonthSubYear
                this.subMonthSubYearTotalOrder = res.data.countOrderSubMonthSubYear
                this.subMonthSubYearTotalWgt = res.data.wgtSubMonthSubYear
                this.orderPercent = res.data.orderPercent
                this.monthYear = res.data.current
                this.monthYearTotalIDR = res.data.orderMonthYear
                this.monthYearTotalOrder = res.data.countOrderMonthYear
                this.monthYearTotalWgt = res.data.wgtMonthYear
                this.monthSubYear = res.data.lastYear
                this.monthSubYearTotalIDR = res.data.orderMonthSubYear
                this.monthSubYearTotalOrder = res.data.countOrderMonthSubYear
                this.monthSubYearTotalWgt = res.data.wgtMonthSubYear

                // this.subMonthYear = res.data.subMonth + ' ' + res.data.year
                this.invSubMonthYearTotalIDR = res.data.invSubMonthYear
                this.invSubMonthYearTotalOrder = res.data.countInvSubMonthYear
                this.invSubMonthYearTotalWgt = res.data.wgtInvSubMonthYear
                // this.subMonthSubYear = res.data.subMonth + ' ' + res.data.subYear
                this.invSubMonthSubYearTotalIDR = res.data.invSubMonthSubYear
                this.invSubMonthSubYearTotalOrder = res.data.countInvSubMonthSubYear
                this.invSubMonthSubYearTotalWgt = res.data.wgtInvSubMonthSubYear

                this.invMonthYear = res.data.month + ' ' + res.data.year
                this.invMonthYearTotalIDR = res.data.invMonthYear
                this.invMonthYearTotalOrder = res.data.countInvMonthYear
                this.invMonthYearTotalWgt = res.data.wgtInvMonthYear
                this.invMonthSubYear = res.data.subMonth + ' ' + res.data.subYear
                this.invMonthSubYearTotalIDR = res.data.invMonthSubYear
                this.invMonthSubYearTotalOrder = res.data.countInvMonthSubYear
                this.invMonthSubYearTotalWgt = res.data.wgtInvMonthSubYear

                this.newCustomerSubMonth = res.data.newCustomerSubMonth
                this.activeOutletSubMonth = res.data.activeOutletSubMonth
                this.customerVisitSubMonth = res.data.visitSubMonth

                this.newCustomerThisMonth = res.data.newCustomerThisMonth
                this.activeOutletThisMonth = res.data.activeOutletThisMonth
                this.customerVisitThisMonth = res.data.visitThisMonth

                this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        renderChart1(data1, data2){

            var chart1 = new CanvasJS.Chart("chartContainer1", {
                    animationEnabled: true,
                    theme: "light2",
                    axisY: {
                        title: "IDR",
                        titleFontSize: 24
                    },
                    data: [{
                        type: "column",
                        indexLabel: "{y}",
                        name: "last year Order",
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "vertical",
                        color: "#07dbf7",
                        yValueFormatString: "###,###",
                        dataPoints: data1,
                    },
                    {
                        type: "column",
                        indexLabel: "{y}",
                        name: "this year Order",
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "vertical",
                        color: "#f79b07",
                        yValueFormatString: "###,###",
                        dataPoints: data2,
                    }]
                })
            
            chart1.render()

        },

        renderChart2(data){

            var chart2 = new CanvasJS.Chart("chartContainer2", {
                animationEnabled: true,
                exportEnabled: false,
                theme: "light2",
                data: [{
                    type: "pie",
                    yValueFormatString: "##0.00\"%\"",
                    dataPoints: data,
                    }]
            })

            chart2.render()

        },

        renderChart3(data){

            var chart3 = new CanvasJS.Chart("chartContainer3", {
                animationEnabled: true,
                exportEnabled: false,
                theme: "light2",
                data: [{
                    type: "pie",
                    yValueFormatString: "##0.00\"%\"",
                    dataPoints: data,
                    }]
            })

            chart3.render()

        },

        renderChart4(data){

            var chart4 = new CanvasJS.Chart("chartContainer4", {
                animationEnabled: true,
                exportEnabled: false,
                theme: "light2",
                data: [{
                    type: "pie",
                    yValueFormatString: "##0.00\"%\"",
                    dataPoints: data,
                    }]
            })

            chart4.render()

        },

        async newCustomerDetails(month, year){

            this.newCustomerDetailsLists = []
            this.newCustomerDialog = true
            this.newCustomerDetailsLoading = true

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/dashboard/getNewCustomerTotalDetails?month=${month}&year=${year}`, { 
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {

                this.newCustomerDetailsLoading = false
                this.newCustomerDetailsLists = res.data.newCustomerDetails

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async newOutletDetails(month, year){

            this.newOutletDetailsLists = []
            this.newOutletDialog = true
            this.newOutletDetailsLoading = true

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/dashboard/getNewOutletTotalDetails?month=${month}&year=${year}`, { 
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {

                this.newOutletDetailsLoading = false
                this.newOutletDetailsLists = res.data.newOutletDetails

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async newVisitDetails(month, year){

            this.newVisitDetailsLists = []
            this.newVisitDialog = true
            this.newVisitDetailsLoading = true

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/dashboard/getNewVisitTotalDetails?month=${month}&year=${year}`, { 
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {

                this.newVisitDetailsLoading = false
                this.newVisitDetailsLists = res.data.newVisitDetails

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        }

    }
    
}
</script>

<style scoped>

    .flex-container {
        display: flex;
        justify-content: space-between;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .container{
        margin-bottom: 60px !important;
    }

    .v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1rem;
    }

    .bgCustom {
        background: #a2d2ff !important;
        color: #fff;
    }

    .bgCustom2 {
        background: #bde0fe !important;
        color: #fff;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.5);
        color: #fff;
    }

    .container{
        margin-bottom: 60px !important;
    }

</style>